import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  CreateInvoicePayload,
  InvoiceDetailResponse,
  UpdateInvoicePayload,
} from '@shared/interfaces/invoice.interface';
import { FileService } from '@Services/file/file.service';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(
    private readonly http: HttpClient,
    private readonly fileService: FileService,
  ) {}

  getInvoices(projectId: string): Observable<unknown> {
    return this.http.get<unknown>(`assetmanagement/projects/${projectId}/invoices/`);
  }

  getInvoiceDetails(projectId: string, invoiceId: string): Observable<InvoiceDetailResponse> {
    return this.http.get<InvoiceDetailResponse>(
      `assetmanagement/projects/${projectId}/invoices/${invoiceId}`,
    );
  }

  createInvoice(
    projectId: string,
    invoice: CreateInvoicePayload,
  ): Observable<InvoiceDetailResponse> {
    return this.http.post<InvoiceDetailResponse>(
      `assetmanagement/projects/${projectId}/invoices/`,
      invoice,
    );
  }

  createProposal(
    projectId: string,
    proposal: CreateInvoicePayload,
  ): Observable<InvoiceDetailResponse> {
    return this.http.post<InvoiceDetailResponse>(
      `assetmanagement/projects/${projectId}/proposals/`,
      proposal,
    );
  }

  updateInvoice(
    projectId: string,
    id: string,
    invoice: UpdateInvoicePayload,
    callEndpoint: 'invoice' | 'proposal',
  ): Observable<InvoiceDetailResponse> {
    switch (callEndpoint) {
      case 'invoice':
        return this.http.put<InvoiceDetailResponse>(
          `assetmanagement/projects/${projectId}/invoices/${id}/`,
          invoice,
        );
      case 'proposal':
        return this.http.put<InvoiceDetailResponse>(
          `assetmanagement/projects/${projectId}/proposals/${id}/`,
          invoice,
        );
      default:
        return of({} as InvoiceDetailResponse);
    }
  }

  downloadInvoice(projectId: string, invoiceId: string): Promise<void> {
    return this.fileService.downloadFile(
      `assetmanagement/projects/${projectId}/invoices/${invoiceId}/download/`,
      `invoice_${projectId}.pdf`,
    );
  }
}
